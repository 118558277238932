import axios from 'axios'
import { showAlert } from './alerts'

export const createOrganisation = async (data) => {
    try {
        // const url = 'http://127.0.0.1:3000/api/v1/inputs'
        const url = '/api/v1/organisations'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Organisation created`)
            window.setTimeout(() => {
                location.assign('/organisations')
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const editOrganisation = async (organisationId, data) => {
    try {
        const url = `/api/v1/organisations/${organisationId}`
        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Organisation edited`)
            window.setTimeout(() => {
                location.assign('/organisations')
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const editOrganisationDetails = async (organisationId, data) => {
    try {
        const url = `/api/v1/organisations/${organisationId}`
        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Organisation edited`)
            return res.data.data.organisation;
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createProxy = async (data) => {
    try {
        const url = '/proxyadmin'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Proxy created`)
            window.setTimeout(() => {
                location.assign('/proxyadmin')
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const editProxy = async (proxyId, data) => {
    try {
        const url = `/proxyadmin/${proxyId}`
        // console.log(data)
        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Proxy edited`)
            window.setTimeout(() => {
                location.assign('/proxyadmin')
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteProxy = async (proxyId, data) => {
    try {
        const url = `/proxyadmin/${proxyId}`
        // console.log(data)
        const res = await axios({
            method: 'DELETE',
            url: url,
            data: data
        })
        // if (res.data.status === 'success') {
        if (res.status === 204) {
            showAlert('success', `Proxy deleted`)
            window.setTimeout(() => {
                location.assign('/proxyadmin')
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createUser = async (data) => {
    try {
        const url = '/api/v1/users/signup'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        console.log(res);
        if (res.data.status === 'success') {
            showAlert('success', `User created`)
            window.setTimeout(() => {
                location.assign('/users')
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createSupplierUser = async (data) => {
    try {
        const url = '/api/v1/users/createsupplier'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `External Evaluation User created`)
            window.setTimeout(() => {
                // location.assign(`/organisation/${data.organisationId}`)
                location.assign(`/suppliers`)
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deactivateSupplier = async (data) => {
    try {
        const url = '/api/v1/users/deactivatesupplier'

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `External user deactivated`)
            window.setTimeout(() => {
                location.assign(`/organisation/${data.organisationId}`)
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateSupplier = async (data, type) => {
    try {
        const url = type === 'password' ? '/api/v1/users/updatesupplierpassword' : '/api/v1/users/updatesupplier'
        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `User edited`)
            window.setTimeout(() => {
                location.assign(`/organisation/${data.organisationId}`)
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

// create suppliers in the admin
export const createSupplierAdmin = async (data) => {
    try {
        const url = '/api/v1/suppliers/admin'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Supplier created`)
            window.setTimeout(() => {
                location.assign(`/adminsuppliers`)
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateUser = async (data) => {
    try {
        const url = `/api/v1/users/${data.userId}`
        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `User edited`)
            window.setTimeout(() => {
                location.assign(`/useradmin/${data.userId}`)
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

// supplier users
export const addSupplierUserToOrg = async (data) => {
    try {
        const url = `/api/v1/organisations/addsupplieruser/${data.supplierUserId}`
        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `External Evaluation User created`)
            window.setTimeout(() => {
                location.assign(`/suppliers`)
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const removeSupplierUserFromOrg = async (data) => {
    try {
        const url = `/api/v1/organisations/addsupplieruser/${data.supplierUserId}`
        const res = await axios({
            method: 'DELETE',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `External Evaluation User Removed`)
            window.setTimeout(() => {
                location.assign(`/suppliers`)
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const fetchColleagueProjects = async (data) => {
    try {
        const url = `/api/v1/organisations/projects/${data.selectedColleagueId}`
        const res = await axios({
            method: 'GET',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            // showAlert('success', `Loading...`)
            return res.data.data.projects;
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateColleagueActive = async (data) => {
    try {
        const url = `/api/v1/organisations/colleagueactivation/${data.selectedColleagueId}`
        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            // showAlert('success', `Colleague Updated`)
            // return res.data.data.projects;
            return res.data;
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

// lead user only to update colleague name and email
export const updateColleagueSettings = async (data) => {
    try {
        const url = `/api/v1/users/updateColleagueSettings/${data.colleagueId}`
        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `User updated successfully`)
            return res.data;
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

// lead user only to update colleague password
export const updateColleaguePassword = async (data) => {
    try {
        const url = '/api/v1/users/updateColleaguePassword'
        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `User password successfully`)
            return res.data;
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createColleague = async (data) => {
    try {
        const url = '/api/v1/users/createColleague'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        // console.log(res);
        if (res.data.status === 'success') {
            console.log(res.data)
            showAlert('success', `User created`)
            window.setTimeout(() => {
                // location.assign(`/organisation/${res.user.organisation._id}`)
                location.assign(`/organisation/${res.data.data.newUser.organisation}`)
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}