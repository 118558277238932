import axios from 'axios'
import { showAlert } from './alerts'

export const createProject = async (data) => {
    try {
        // const url = 'http://127.0.0.1:3000/api/v1/projects'
        const url = '/api/v1/projects'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `${data.name} created`)
            window.setTimeout(() => {
                location.assign('/project/all/page/1')
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteProject = async (projectId) => {
    try {
        const url = `/api/v1/projects/${projectId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `Project deleted`)
            window.setTimeout(() => {
                location.assign('/project/page/1')
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateProject = async (projectId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/projects/${projectId}`
        const url = `/api/v1/projects/${projectId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Saved`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const transferProject = async (projectId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/projects/${projectId}`
        const url = `/api/v1/projects/transfer/${projectId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Saved`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const duplicateProject = async (data) => {
    try {
        const url = '/api/v1/projects/duplicate'
        const res = await axios({
            method: 'POST',
            url: url,
            // this below here needs to be the duplicated project
            data: data
        })
        // console.log(data)
        if (res.data.status === 'success') {
            // showAlert('success', `${data.name} created`)
            showAlert('success', `Project duplicated`)
            window.setTimeout(() => {
                location.assign('/project/all/page/1')
                // location.assign(`/project/${data.type}/page/1`)
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createTarget = async (data) => {
    try {
        // const url = 'http://127.0.0.1:3000/api/v1/targets'
        const url = '/api/v1/targets'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Target created`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateTarget = async (targetId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/targets/${targetId}`
        const url = `/api/v1/targets/${targetId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Target updated`)
            // window.setTimeout(() => {
            //     location.assign(`/project/${targetId}`)
            // }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteTarget = async (targetId) => {
    try {
        const url = `/api/v1/targets/${targetId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `Target deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createIndicator = async (data) => {
    try {
        const url = '/api/v1/indicators'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Indicator created`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateIndicator = async (indicatorId, data) => {
    try {
        const url = `/api/v1/indicators/${indicatorId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Indicator updated`)
            // window.setTimeout(() => {
            //     location.assign(`/project/${targetId}`)
            // }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteIndicator = async (indicatorId) => {
    try {
        const url = `/api/v1/indicators/${indicatorId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `Indicator deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createOutput = async (data) => {
    try {
        // const url = 'http://127.0.0.1:3000/api/v1/outputs'
        const url = '/api/v1/outputs'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Outcome created`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateOutput = async (outputId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/outputs/${outputId}`
        const url = `/api/v1/outputs/${outputId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Outcome updated`)
            // window.setTimeout(() => {
            //     location.assign(`/project/${targetId}`)
            // }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteOutput = async (outputId) => {
    try {
        const url = `/api/v1/outputs/${outputId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `Outcome deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}


export const createInput = async (data) => {
    try {
        const url = '/api/v1/inputs'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Input created`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateInput = async (inputId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/inputs/${inputId}`
        const url = `/api/v1/inputs/${inputId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Input updated`)
            // window.setTimeout(() => {
            //     location.assign(`/project/${targetId}`)
            // }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteInput = async (inputId) => {
    try {
        const url = `/api/v1/inputs/${inputId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `Input deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const addProxy = async (data) => {
    try {
        // const url = 'http://127.0.0.1:3000/outcome'
        const url = '/outcome'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Added`)
            // location.reload()
            location.assign(`/outcome/${data.project}`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createStakeholder = async (data) => {
    try {
        // const url = `http://127.0.0.1:3000/stakeholder`
        const url = `/stakeholder`

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Stakeholder created`);
            location.reload();
            return { reloadForm: true };
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateStakeholder = async (data) => {
    try {
        // const url = `http://127.0.0.1:3000/stakeholder`
        const url = `/stakeholder`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Stakeholder updated`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteStakeholder = async (data) => {
    try {
        const url = `/stakeholder`

        const res = await axios({
            method: 'DELETE',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Stakeholder deleted`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteProxyFromProject = async (data) => {
    try {
        const url = `/measure`

        const res = await axios({
            method: 'DELETE',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Proxy removed from project`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createProgramme = async (data) => {
    try {
        const url = '/api/v1/programmes'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Programme created`)
        }
    } catch (err) {
        // showAlert('error', err.response.data.message)
    }
}

export const deleteProgramme = async (programmeId) => {
    try {
        const url = `/api/v1/programmes/${programmeId}`

        const res = await axios({
            method: 'DELETE',
            url: url
            // data: data
        })
        if (res.status === 204) {
            showAlert('success', `Programme deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

// outputs two
export const createOutputTwo = async (data) => {
    try {
        const url = '/api/v1/outputsTwo'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Output created`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateOutputTwo = async (outputTwoId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/inputs/${inputId}`
        const url = `/api/v1/outputsTwo/${outputTwoId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Output updated`)
            // window.setTimeout(() => {
            //     location.assign(`/project/${targetId}`)
            // }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteOutputTwo = async (outputTwoId) => {
    try {
        const url = `/api/v1/outputsTwo/${outputTwoId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `Output deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const createKpi = async (data) => {
    try {
        const url = '/api/v1/kpis'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `KPI created`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
        console.log(err)
    }
}

export const updateKpi = async (kpiId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/inputs/${inputId}`
        const url = `/api/v1/kpis/${kpiId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `KPI updated`)
            // window.setTimeout(() => {
            //     location.assign(`/project/${targetId}`)
            // }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteKpi = async (kpiId) => {
    try {
        const url = `/api/v1/kpis/${kpiId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `KPI deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

// Inputs Actual
export const createInputActual = async (data) => {
    try {
        const url = '/api/v1/inputsActual'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Input created`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateInputActual = async (inputActualId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/inputs/${inputId}`
        const url = `/api/v1/inputsActual/${inputActualId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Input updated`)
            // window.setTimeout(() => {
            //     location.assign(`/project/${targetId}`)
            // }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteInputActual = async (inputActualId) => {
    try {
        const url = `/api/v1/InputsActual/${inputActualId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `Input deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

// Outcomes Actual
export const createOutcomeActual = async (data) => {
    try {
        const url = '/api/v1/outcomesActual'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Outcome created`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateOutcomeActual = async (outcomeActualId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/inputs/${inputId}`
        const url = `/api/v1/outcomesActual/${outcomeActualId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `Outcome updated`)
            // window.setTimeout(() => {
            //     location.assign(`/project/${targetId}`)
            // }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteOutcomeActual = async (outcomeActualId) => {
    try {
        const url = `/api/v1/outcomesActual/${outcomeActualId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `Outcome deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

// KPI Actual
export const createKpiActual = async (data) => {
    try {
        const url = '/api/v1/kpisActual'

        const res = await axios({
            method: 'POST',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `KPI created`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const updateKpiActual = async (kpiActualId, data) => {
    try {
        // const url = `http://127.0.0.1:3000/api/v1/inputs/${inputId}`
        const url = `/api/v1/kpisActual/${kpiActualId}`

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `KPI updated`)
            // window.setTimeout(() => {
            //     location.assign(`/project/${targetId}`)
            // }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const deleteKpiActual = async (kpiActualId) => {
    try {
        const url = `/api/v1/kpisActual/${kpiActualId}`

        const res = await axios({
            method: 'DELETE',
            url: url
        })
        if (res.status === 204) {
            showAlert('success', `KPI deleted`)
            window.setTimeout(() => {
                location.reload()
            }, 1000)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}