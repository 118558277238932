import axios from 'axios'
import { showAlert } from './alerts'

export const login = async (email, password) => {
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/users/login',
            data: {
                email: email,
                password: password
            }
        });
        console.log('Login response received:', res.data);
        if (res.data.status === 'success') {
            showAlert('success', 'Logged in successfully')
            window.setTimeout(() => {
                location.assign('/me')
            }, 1500)
        } else if (res.data.status === 'setup') {
            console.log(res.data)
            // Display TOTP setup page
            showAlert('success', 'Redirecting');
            window.setTimeout(() => {
                location.assign('/setup-totp');
            }, 1500);
        } else if (res.data.status === 'pending') {
            showAlert('info', 'Please enter your one time passcode');
            window.setTimeout(() => {
                location.assign('/verify-totp');
            }, 1500);
        }
    } catch (err) {
        console.error('Login error:', err.response.data);
        showAlert('error', err.response.data.message)
    }
}

export const logout = async () => {
    try {
        const res = await axios({
            method: 'GET',
            url: '/api/v1/users/logout'
        })
        // if (res.data.status = 'success') location.reload(true)
        if ((res.data.status = 'success')) location.assign('/login')
    } catch (err) {
        showAlert('error', 'Error logging out! Try again.')
    }
}

export const forgotPassword = async (email) => {
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/users/forgotPassword',
            data: {
                email: email
            }
        })
        if (res.data.status === 'success') {
            showAlert('success', 'Email sent')
            window.setTimeout(() => {
                location.reload()
            }, 1500)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const resetPassword = async (password, passwordConfirm, resetToken) => {
    try {
        const res = await axios({
            method: 'PATCH',
            url: `/api/v1/users/resetPassword/${resetToken}`,
            data: {
                password: password,
                passwordConfirm: passwordConfirm
            }
        })
        if (res.data.status === 'success') {
            showAlert('success', 'Password changed successfully')
            window.setTimeout(() => {
                // login and redirect to user home
                location.assign('/me')
            }, 1500)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const verify2fa = async (totpCode) => {
    try {
        const res = await axios({
            method: 'POST',
            url: `/api/v1/users/verify-totp`,
            data: {
                totpCode: totpCode
            }
        })
        if (res.data.status === 'success') {
            showAlert('success', 'Logged in')
            window.setTimeout(() => {
                // login and redirect to user home
                location.assign('/me')
            }, 1500)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}


