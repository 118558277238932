const mapMarkers = [];

export function addMapMarker(marker, transactionId) {
    mapMarkers.push({ marker, transactionId });
}

export function removeMapMarkerByTransactionId(transactionId) {
    const markerIndex = mapMarkers.findIndex(entry => entry.transactionId === transactionId);
    if (markerIndex > -1) {
        const { marker } = mapMarkers.splice(markerIndex, 1)[0];
        return marker;
    }
    return null;
}

export function getMapMarkers() {
    return mapMarkers;
}
