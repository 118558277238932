import { createProject, updateProject, duplicateProject, createTarget, updateTarget, deleteTarget, createIndicator, updateIndicator, deleteIndicator, createOutput, updateOutput, deleteOutput, createInput, updateInput, createOutputTwo, deleteOutputTwo, createKpi, deleteKpi, deleteInput, createStakeholder, updateStakeholder, deleteStakeholder, deleteProgramme, deleteProxyFromProject, addProxy, updateInputActual, updateOutcomeActual, updateKpiActual, deleteInputActual, deleteOutcomeActual, deleteKpiActual } from './project'
import { deactivateSupplier, updateSupplier, createSupplierUser, removeSupplierUserFromOrg } from './admin'
import { showAlert } from './alerts'
import moment from 'moment'
import axios from 'axios';

const outcomeTotalElement = document.getElementById('outcomeActualTotal');
const inputTotalElement = document.getElementById('inputActualTotal');
const npvElement = document.getElementById('npvLedger');
const sroiElement = document.getElementById('actualSroiRatio');

export const duplicateProjectComponent = async (e) => {
    e.preventDefault()
    const project = document.getElementById('projectId').value
    await duplicateProject({ project })
}


export const updateTargetComponent = async (e) => {
    e.preventDefault()
    const targetId = document.getElementById('targetId').value
    const title = document.getElementById('titleUpdate').value
    const targetDescription = document.getElementById('targetUpdateDescription').value
    const targetCategory = document.getElementById('targetUpdateCategory').value
    const targetFulfilled = document.getElementById('targetUpdateFulfilled').value
    const targetForecast = document.getElementById('targetUpdateForecast').value
    const modifiedAt = Date.now()

    await updateTarget(targetId, { title, targetDescription, targetCategory, targetFulfilled, targetForecast, modifiedAt })

    document.querySelector('.btn--update-target').textContent = 'Updating...'
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const deleteTargetComponent = async (event) => {
    const targetId = event.target.getAttribute('data-key')
    await deleteTarget(targetId)
}

export const duplicateTargetComponent = async (e) => {
    const project = e.target.getAttribute('data-project')
    const title = e.target.getAttribute('data-title') + ' copy'
    const targetFulfilled = e.target.getAttribute('data-targetFulfilled')
    const targetForecast = e.target.getAttribute('data-targetforecast')
    await createTarget({ title, targetForecast, targetFulfilled, project })
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const updateStakeholderComponent = async (e) => {
    e.preventDefault()
    const project = document.getElementById('projectId').value
    const stakeholderId = document.getElementById('stakeholderId').value
    const stakeholderTitle = document.getElementById('updateTitle').value
    const evidenceOfOutcome = document.getElementById('stakeholderEvidenceOfOutcome').value
    const stakeholderInvolvement = document.getElementById('stakeholderInvolvement').value
    const stakeholderInclusion = document.getElementById('stakeholderInclusion').value
    // const reasonForInclusion = document.getElementById('stakeholderReasonForInclusion').value
    // const positiveNegative = document.getElementById('stakeholderPositiveNegative').value
    // const method = document.getElementById('stakeholderMethod').value
    // const when = document.getElementById('stakeholderWhen').value
    let subgroup

    // console.log(document.querySelector('subgroupEdit'))
    if (document.querySelector('input[name = "subgroupEdit"]:checked').value === 'editpositive') {
        subgroup = [
            {
                subgroupTitle: document.getElementById('subGroupOneEdit').value,
                subgroupPercent: document.getElementById('subGroupOneEditPercent').value
            },
            {
                subgroupTitle: document.getElementById('subGroupTwoEdit').value,
                subgroupPercent: document.getElementById('subGroupTwoEditPercent').value
            },
            {
                subgroupTitle: document.getElementById('subGroupThreeEdit').value,
                subgroupPercent: document.getElementById('subGroupThreeEditPercent').value
            },
            {
                subgroupTitle: document.getElementById('subGroupFourEdit').value,
                subgroupPercent: document.getElementById('subGroupFourEditPercent').value
            }
        ]
        // Below if conditional to stop percentage being over 100 on the front-end
        // if (Number(subgroup[0].subgroupPercent) + Number(subgroup[1].subgroupPercent) + Number(subgroup[2].subgroupPercent) + Number(subgroup[3].subgroupPercent) > 100) {
        //     showAlert('error', 'Sub-group total cannot exceed 100%')
        //     return false
        // } else {
        //     // await updateStakeholder({ project, stakeholderId, stakeholderTitle, reasonForInclusion, positiveNegative, method, when, subgroup })
        //     await updateStakeholder({ project, stakeholderId, stakeholderTitle, evidenceOfOutcome, subgroup })
        // }
        await updateStakeholder({ project, stakeholderId, stakeholderTitle, evidenceOfOutcome, stakeholderInvolvement, stakeholderInclusion, subgroup })
    } else { }
    // alert(document.querySelector('input[name = "subgroupEdit"]:checked').value)

    // await updateStakeholder({ project, stakeholderId, stakeholderTitle, reasonForInclusion, positiveNegative, method, when })
    await updateStakeholder({ project, stakeholderId, stakeholderTitle, stakeholderInvolvement, stakeholderInclusion, evidenceOfOutcome })

    document.querySelector('.btn--update-stakeholder').textContent = 'Updating...'
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const deleteStakeholderComponent = async (event) => {
    const project = event.target.getAttribute('data-project')
    const stakeholderId = event.target.getAttribute('data-key')
    await deleteStakeholder({ project, stakeholderId })
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const duplicateStakeholderComponent = async (event) => {
    const project = event.target.getAttribute('data-project')
    const stakeholders = {
        stakeholderTitle: event.target.getAttribute('data-stakeholderTitle') + ' copy',
        evidenceOfOutcome: event.target.getAttribute('data-evidenceOfOutcome'),
        stakeholderInclusion: event.target.getAttribute('data-stakeholderInclusion'),
        stakeholderInvolvement: event.target.getAttribute('data-stakeholderInvolvement'),
        positiveNegative: event.target.getAttribute('data-positiveNegative'),
        method: event.target.getAttribute('data-method'),
        when: event.target.getAttribute('data-when'),
        population: event.target.getAttribute('data-population')
    }
    await createStakeholder({ stakeholders, project })
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const deleteIndicatorComponent = async (event) => {
    const indicatorId = event.target.getAttribute('data-key')
    await deleteIndicator(indicatorId)
}

export const duplicateIndicatorComponent = async (event) => {
    const title = event.target.getAttribute('data-title') + ' copy'
    // const volume = event.target.getAttribute('data-volume')
    // const value = event.target.getAttribute('data-value')
    const project = event.target.getAttribute('data-project')
    const description = event.target.getAttribute('data-description')
    const subjective = event.target.getAttribute('data-subjective')
    const measurementUnit = event.target.getAttribute('data-measurementUnit')
    const benchmark = event.target.getAttribute('data-benchmark')
    const baseline = event.target.getAttribute('data-baseline')
    const measurement = event.target.getAttribute('data-measurement')
    const indicatorURL = event.target.getAttribute('data-indicatorURL')
    const indicatorQuantity = event.target.getAttribute('data-indicatorQuantity')
    const outcomeId = event.target.getAttribute('data-outcomeid')
    // console.log(outcomeId)
    // await createIndicator({ title, project })
    if (outcomeId !== 'undefined') {
        await createIndicator({ title, description, subjective, outcomeId, indicatorQuantity, benchmark, measurementUnit, baseline, measurement, indicatorURL, project })
    } else {
        await createIndicator({ title, description, subjective, indicatorQuantity, benchmark, measurementUnit, baseline, measurement, indicatorURL, project })
    }
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const updateOutputComponent = async (e) => {
    e.preventDefault()
    const outputId = document.getElementById('outputId').value;
    const userOutcome = document.getElementById('userOutcomeUpdate');
    const deflatorDescriptionElement = document.getElementById('deflatorDescriptionUpdate');
    const ownFrameworkElement = document.getElementById('ownFrameworkUpdate');
    const geographyUpdateElement = document.getElementById('geographyUpdate');
    const startDate = document.getElementById('startDateUpdate');
    const endDate = document.getElementById('endDateUpdate')

    const formData = {
        title: document.getElementById('titleUpdate').value,
        quantity: document.getElementById('quantityUpdate').value,
        timePeriod: document.getElementById('timePeriodUpdate').value,
        leakage: document.getElementById('leakageUpdate').value,
        deadweight: document.getElementById('deadweightUpdate').value,
        attribution: document.getElementById('attributionUpdate').value,
        displacement: document.getElementById('displacementUpdate').value,
        dropOff: document.getElementById('dropOffUpdate').value,
    };

    if (geographyUpdateElement) {
        formData.geocode = {
            locationId: geographyUpdateElement.getAttribute('data-location-id'),
            lat: geographyUpdateElement.getAttribute('data-lat'),
            lng: geographyUpdateElement.getAttribute('data-lng'),
        };
    }
    if (userOutcome) {
        formData.userOutcome = userOutcome.value;
    }
    if (startDate) {
        formData.startDate = startDate.value;
        formData.endDate = endDate.value;
    }
    if (deflatorDescriptionElement) {
        formData.deflatorDescription = deflatorDescriptionElement.value;
    }
    if (ownFrameworkElement) {
        formData.ownFramework = ownFrameworkElement.value;
    }
    // console.log(outputId, formData)
    await updateOutput(outputId, formData)
    // await updateOutput(outputId, formData)
    // if (deflatorDescriptionElement) {
    //     const deflatorDescription = deflatorDescriptionElement.value;
    //     await updateOutput(outputId, { title, userOutcome, deflatorDescription, quantity, timePeriod, leakage, deadweight, attribution, displacement, dropOff, startDate, endDate })
    // } else if (ownFrameworkElement) {
    //     const ownFramework = ownFrameworkElement.value;
    //     await updateOutput(outputId, { title, userOutcome, ownFramework, quantity, timePeriod, leakage, deadweight, attribution, displacement, dropOff, startDate, endDate })
    // }

    document.querySelector('.btn--update-output').textContent = 'Updating...'
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const deleteOutputComponent = async (event) => {
    const outputId = event.target.getAttribute('data-key')
    await deleteOutput(outputId)
}

export const duplicateOutputComponent = async (event) => {
    const title = event.target.getAttribute('data-title') + ' copy'
    // const indicators = event.target.getAttribute('data-indicators')
    const userOutcome = event.target.getAttribute('data-userOutcome')
    const deflatorDescription = event.target.getAttribute('data-deflatorDescription')
    const quantity = event.target.getAttribute('data-quantity')
    const timePeriod = event.target.getAttribute('data-timePeriod')
    const leakage = event.target.getAttribute('data-leakage')
    const deadweight = event.target.getAttribute('data-deadweight')
    const attribution = event.target.getAttribute('data-attribution')
    const displacement = event.target.getAttribute('data-displacement')
    const dropOff = event.target.getAttribute('data-dropOff')
    const value = event.target.getAttribute('data-value')
    const bristol = event.target.getAttribute('data-bristol')
    const sdg = event.target.getAttribute('data-sdg')
    const dg = event.target.getAttribute('data-dg')
    const barns = event.target.getAttribute('data-barns')
    const proxyTitle = event.target.getAttribute('data-proxyTitle')
    const outcome = event.target.getAttribute('data-outcome')
    const unit = event.target.getAttribute('data-unit')
    const anchor = event.target.getAttribute('data-anchor')
    const source = event.target.getAttribute('data-source')
    const stakeholder = event.target.getAttribute('data-stakeholder')
    const afterDuring = event.target.getAttribute('data-afterDuring')
    const positiveNegative = event.target.getAttribute('data-positiveNegative')
    const startDate = event.target.getAttribute(Date('data-startDate'))
    // const startDate = '2012-05-05'
    const endDate = event.target.getAttribute(Date('data-endDate'))
    // const endDate = '2012-05-05'
    const project = event.target.getAttribute('data-project')

    await createOutput({ title, userOutcome, deflatorDescription, quantity, stakeholder, timePeriod, leakage, deadweight, attribution, displacement, dropOff, value, bristol, sdg, dg, barns, proxyTitle, outcome, unit, anchor, source, stakeholder, afterDuring, positiveNegative, startDate, endDate, project })
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const deleteInputComponent = async (event) => {
    const inputId = event.target.getAttribute('data-key')
    await deleteInput(inputId)
}

export const duplicateInputComponent = async (event) => {
    const title = event.target.getAttribute('data-title') + ' copy'
    const volume = event.target.getAttribute('data-volume')
    const value = event.target.getAttribute('data-value')
    const stakeholder = event.target.getAttribute('data-stakeholder')
    const inputToActivity = event.target.getAttribute('data-inputToActivity')
    const inputNotes = event.target.getAttribute('data-inputNotes')
    const project = event.target.getAttribute('data-project')

    await createInput({ title, volume, value, stakeholder, inputToActivity, inputNotes, project })
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const deleteOutputTwoComponent = async (event) => {
    const outputTwoId = event.target.getAttribute('data-key')
    await deleteOutputTwo(outputTwoId)
}

export const duplicateOutputTwoComponent = async (event) => {
    const title = event.target.getAttribute('data-title') + ' copy'
    const volume = event.target.getAttribute('data-volume')
    const description = event.target.getAttribute('data-description')
    const stakeholder = event.target.getAttribute('data-stakeholder')
    const outputToActivity = event.target.getAttribute('data-outputToActivity')
    const project = event.target.getAttribute('data-project')

    await createOutputTwo({ title, volume, description, stakeholder, outputToActivity, project })
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const deleteKpiComponent = async (event) => {
    const kpiId = event.target.getAttribute('data-key')
    await deleteKpi(kpiId)
}

export const duplicateKpiComponent = async (event) => {
    const kpiTitle = event.target.getAttribute('data-title') + ' copy'
    const kpiForecast = event.target.getAttribute('data-forecast')
    const project = event.target.getAttribute('data-project')
    const kpiId = event.target.getAttribute('data-parent-id')

    await createKpi({ kpiTitle, kpiForecast, project, kpiId })
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const deleteProgrammeComponent = async (event) => {
    const programmeId = event.target.getAttribute('data-key')
    await deleteProgramme(programmeId)
}

export const deleteProxyFromProjectComponent = async (event) => {
    // const proxyId = event.target.getAttribute('data-key')
    // await deleteProxyFromProject(proxyId)
    const project = event.target.getAttribute('data-project')
    const proxyId = event.target.getAttribute('data-key')
    // console.log(project, proxyId)
    await deleteProxyFromProject({ project, proxyId })
    window.setTimeout(() => {
        location.reload()
    }, 1000)
}

export const setAttributes = (element, attrs) => {
    for (let key in attrs) {
        element.setAttribute(key, attrs[key])
    }
}

export const trimLength = (str) => {
    if (str.length < 40)
        return str
    else
        return str.substring(0, 40) + '...'
}

export const trimTransaction = (str) => {
    if (str.length < 25)
        return str
    else
        return str.substring(0, 25) + '...'
}

export const trimTransactionTitle = (str) => {
    if (str.length < 50)
        return str
    else
        return str.substring(0, 50) + '...'
}

// export const formatDate = (date) => {
//     const now = moment();
//     const weekAgo = moment().subtract(1, 'week');

//     if (moment(date).isSameOrAfter(weekAgo)) {
//         return moment(date).fromNow();
//     } else {
//         return moment(date).format('DD/MM/YYYY');
//     }
// }

export const formatDate = (transactionDate) => {
    const now = moment();
    const date = moment(transactionDate, moment.ISO_8601);
    const diffInDays = now.diff(date, 'days');
    if (diffInDays < 0) {
        return moment(date).format('DD-MM-YYYY'); // output: "April 1st 2022"
    } else if (diffInDays < 7) {
        return moment(date).fromNow();
    } else {
        return moment(date).format('DD-MM-YYYY');
    }
}

// update the DOM elements for the ledger 
// export const updateLedger = (ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement, sroiElement) => {
// export const updateLedger = (ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement) => {
//     axios.get(`/populateledger/${projectId}`)
//         .then(response => {
//             // const transactions = response.data;
//             console.log(response.data)
//             const { inputActuals, outcomeActuals, kpiActuals, projectInputActualTotal, projectOutcomeActualTotal, projectActualNetPresentValue, projectActualSroiRatio } = response.data;
//             const combinedTransactions = [];
//             const inputLength = inputActuals ? inputActuals.length : 0;
//             const outcomeLength = outcomeActuals ? outcomeActuals.length : 0;
//             const kpiLength = kpiActuals ? kpiActuals.length : 0;

//             const maxLength = Math.max(inputLength, outcomeLength, kpiLength);
//             console.log(inputActuals)
//             // for (let i = 0; i < inputActuals.length || i < outcomeActuals.length || i < kpiActuals.length; i++) {
//             //     if (i < inputActuals.length) {
//             //         combinedTransactions.push({ value: inputActuals[i], type: 'input' });
//             //     }
//             //     if (i < outcomeActuals.length) {
//             //         combinedTransactions.push({ value: outcomeActuals[i], type: 'outcome' });
//             //     }
//             //     if (i < kpiActuals.length) {
//             //         combinedTransactions.push({ value: kpiActuals[i], type: 'kpi' });
//             //     }
//             // }

//             for (let i = 0; i < maxLength; i++) {
//                 if (i < inputLength) {
//                     // Process inputActuals[i]
//                     combinedTransactions.push({ value: inputActuals[i], type: 'input' });
//                 }
//                 if (i < outcomeLength) {
//                     // Process outcomeActuals[i]
//                     combinedTransactions.push({ value: outcomeActuals[i], type: 'outcome' });
//                 }
//                 if (i < kpiLength) {
//                     // Process kpiActuals[i]
//                     combinedTransactions.push({ value: kpiActuals[i], type: 'kpi' });
//                 }
//             }

//             ledger.innerHTML = '';
//             combinedTransactions.sort((a, b) => new Date(b.value.date) - new Date(a.value.date));
//             combinedTransactions.forEach((item, i) => {
//                 let html = '';
//                 if (item.type === 'input') {
//                     html = `<div id="transaction-input" class="movements__row" data-id="${item.value._id}" data-title="${item.value.inputTitle}" data-type="${item.type}" data-cost="${item.value.cost}" data-quantity="${item.value.quantity}" data-date="${item.value.date}" data-geo="${item.value.geocode.locationId}"->
//                     <div class="movements__type movements__type--${item.type}">${trimTransaction(item.value.inputTitle)}</div>
//                     <div class="movements__date">${formatDate(item.value.date)}</div>
//                     <div class="movements__value">£${((item.value.impact).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}</div>
//                   </div>`;
//                 } else if (item.type === 'outcome') {
//                     html = `<div id="transaction-outcome" class="movements__row" data-id="${item.value._id}" data-title="${item.value.outputTitle}" data-type="${item.type}" data-quantity="${item.value.quantity}" data-date="${item.value.date}" data-geo="${item.value.geocode.locationId}">
//                     <div class="movements__type movements__type--${item.type}">${trimTransaction(item.value.outputTitle)}</div>
//                     <div class="movements__date">${formatDate(item.value.date)}</div>
//                     <div class="movements__value">${item.value.quantity}</div>
//                   </div>`;
//                 } else if (item.type === 'kpi') {
//                     html = `<div id="transaction-kpi" class="movements__row" data-id="${item.value._id}" data-title="${item.value.kpiTitle}" data-type="${item.type}" data-quantity="${item.value.kpiQuantity}" data-date="${item.value.date}" data-geo="${item.value.geocode.locationId}">
//                     <div class="movements__type movements__type--${item.type}">${trimTransaction(item.value.kpiTitle)}</div>
//                     <div class="movements__date">${formatDate(item.value.date)}</div>
//                     <div class="movements__value">${item.value.kpiQuantity}</div>
//                   </div>`;
//                 }
//                 ledger.insertAdjacentHTML('beforeend', html);
//             });
//             inputTotalElement.innerHTML = projectInputActualTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
//             outcomeTotalElement.innerHTML = projectOutcomeActualTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
//             npvElement.innerHTML = projectActualNetPresentValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
//             // sroiElement.innerHTML = projectActualSroiRatio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
//         })
//         .catch(error => {
//             if (error.response && error.response.status === 404) {
//                 showAlert('error', 'Ledger not found');
//                 // } else if (!inputActuals && !outcomeActuals || inputActuals.length === 0 && outcomeActuals.length === 0) {
//             } else if ((!inputActuals || inputActuals.length) === 0 && outcomeActuals.length === 0) {
//                 ledger.innerHTML = 'Ledger empty';
//             } else {
//                 ledger.innerHTML = '';
//             }
//         });
// }


export const fetchDataAndUpdateUI = async (projectId, ledger) => {
    try {
        const response = await axios.get(`/populateledger/${projectId}`);
        const { inputActuals, outcomeActuals, kpiActuals, projectInputActualTotal, projectOutcomeActualTotal, projectActualNetPresentValue, projectActualSroiRatio } = response.data;
        const combinedTransactions = [];
        const inputLength = inputActuals ? inputActuals.length : 0;
        const outcomeLength = outcomeActuals ? outcomeActuals.length : 0;
        const kpiLength = kpiActuals ? kpiActuals.length : 0;

        const maxLength = Math.max(inputLength, outcomeLength, kpiLength);

        for (let i = 0; i < maxLength; i++) {
            if (i < inputLength) {
                // Process inputActuals[i]
                combinedTransactions.push({ value: inputActuals[i], type: 'input' });
            }
            if (i < outcomeLength) {
                // Process outcomeActuals[i]
                combinedTransactions.push({ value: outcomeActuals[i], type: 'outcome' });
            }
            if (i < kpiLength) {
                // Process kpiActuals[i]
                combinedTransactions.push({ value: kpiActuals[i], type: 'kpi' });
            }
        }
        ledger.innerHTML = '';
        combinedTransactions.sort((a, b) => new Date(b.value.date) - new Date(a.value.date));
        combinedTransactions.forEach((item, i) => {
            let html = '';
            // if the lat and lng don't exist, this will throw an error
            if (item.type === 'input') {
                let locationId = item.value.geocode && item.value.geocode.locationId ? item.value.geocode.locationId : '';
                let lat = item.value.geocode && item.value.geocode.lat ? item.value.geocode.lat : '';
                let lng = item.value.geocode && item.value.geocode.lng ? item.value.geocode.lng : '';

                html = `<div id="transaction-input" class="movements__row" data-id="${item.value._id}" data-title="${item.value.inputTitle}" data-type="${item.type}" data-cost="${item.value.cost}" data-quantity="${item.value.quantity}" data-date="${item.value.date}" data-geo="${locationId}" data-lat="${lat}" data-lng="${lng}">
                    <div class="movements__type movements__type--${item.type}">${trimTransaction(item.value.inputTitle)}</div>
                    <div class="movements__date">${formatDate(item.value.date)}</div>
                    <div class="movements__value">£${((item.value.impact).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}</div>
                  </div>`;
            } else if (item.type === 'outcome') {
                let locationId = item.value.geocode && item.value.geocode.locationId ? item.value.geocode.locationId : '';
                let lat = item.value.geocode && item.value.geocode.lat ? item.value.geocode.lat : '';
                let lng = item.value.geocode && item.value.geocode.lng ? item.value.geocode.lng : '';
                html = `<div id="transaction-outcome" class="movements__row" data-id="${item.value._id}" data-title="${item.value.outputTitle}" data-type="${item.type}" data-quantity="${item.value.quantity}" data-date="${item.value.date}" data-geo="${locationId}" data-lat="${lat}" data-lng="${lng}">
                    <div class="movements__type movements__type--${item.type}">${trimTransaction(item.value.outputTitle)}</div>
                    <div class="movements__date">${formatDate(item.value.date)}</div>
                    <div class="movements__value">${item.value.quantity}</div>
                  </div>`;
            } else if (item.type === 'kpi') {
                let locationId = item.value.geocode && item.value.geocode.locationId ? item.value.geocode.locationId : '';
                let lat = item.value.geocode && item.value.geocode.lat ? item.value.geocode.lat : '';
                let lng = item.value.geocode && item.value.geocode.lng ? item.value.geocode.lng : '';
                html = `<div id="transaction-kpi" class="movements__row" data-id="${item.value._id}" data-title="${item.value.kpiTitle}" data-type="${item.type}" data-quantity="${item.value.kpiQuantity}" data-date="${item.value.date}" data-geo="${locationId}" data-lat="${lat}" data-lng="${lng}">
                    <div class="movements__type movements__type--${item.type}">${trimTransaction(item.value.kpiTitle)}</div>
                    <div class="movements__date">${formatDate(item.value.date)}</div>
                    <div class="movements__value">${item.value.kpiQuantity}</div>
                  </div>`;
            }
            ledger.insertAdjacentHTML('beforeend', html);
        });

        await updateUIWithData(inputActuals, outcomeActuals, kpiActuals, projectInputActualTotal, projectOutcomeActualTotal, projectActualNetPresentValue, projectActualSroiRatio);
    } catch (error) {
        if (error.response && error.response.status === 404) {
            showAlert('error', 'Ledger not found');
            // } else if (!inputActuals && !outcomeActuals || inputActuals.length === 0 && outcomeActuals.length === 0) {
        } else if ((!inputActuals || inputActuals.length) === 0 && (!outcomeActuals && outcomeActuals.length === 0)) {
            // } else if (!inputActuals && !outcomeActuals) {
            ledger.innerHTML = 'Ledger empty';
        } else {
            ledger.innerHTML = '';
        }
    }
}

// export const updateLedger = (ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement) => {
//     axios.get(`/populateledger/${projectId}`)
//         .then(response => {
//             // const transactions = response.data;
//             console.log(response.data)
//             const { inputActuals, outcomeActuals, kpiActuals, projectInputActualTotal, projectOutcomeActualTotal, projectActualNetPresentValue, projectActualSroiRatio } = response.data;
//             const combinedTransactions = [];
//             const inputLength = inputActuals ? inputActuals.length : 0;
//             const outcomeLength = outcomeActuals ? outcomeActuals.length : 0;
//             const kpiLength = kpiActuals ? kpiActuals.length : 0;

//             const maxLength = Math.max(inputLength, outcomeLength, kpiLength);
//             console.log(inputActuals)

//             for (let i = 0; i < maxLength; i++) {
//                 if (i < inputLength) {
//                     // Process inputActuals[i]
//                     combinedTransactions.push({ value: inputActuals[i], type: 'input' });
//                 }
//                 if (i < outcomeLength) {
//                     // Process outcomeActuals[i]
//                     combinedTransactions.push({ value: outcomeActuals[i], type: 'outcome' });
//                 }
//                 if (i < kpiLength) {
//                     // Process kpiActuals[i]
//                     combinedTransactions.push({ value: kpiActuals[i], type: 'kpi' });
//                 }
//             }
//             ledger.innerHTML = '';
//             combinedTransactions.sort((a, b) => new Date(b.value.date) - new Date(a.value.date));
//             combinedTransactions.forEach((item, i) => {
//                 let html = '';
//                 if (item.type === 'input') {
//                     html = `<div id="transaction-input" class="movements__row" data-id="${item.value._id}" data-title="${item.value.inputTitle}" data-type="${item.type}" data-cost="${item.value.cost}" data-quantity="${item.value.quantity}" data-date="${item.value.date}" data-geo="${item.value.geocode.locationId}"->
//                     <div class="movements__type movements__type--${item.type}">${trimTransaction(item.value.inputTitle)}</div>
//                     <div class="movements__date">${formatDate(item.value.date)}</div>
//                     <div class="movements__value">£${((item.value.impact).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}</div>
//                   </div>`;
//                 } else if (item.type === 'outcome') {
//                     html = `<div id="transaction-outcome" class="movements__row" data-id="${item.value._id}" data-title="${item.value.outputTitle}" data-type="${item.type}" data-quantity="${item.value.quantity}" data-date="${item.value.date}" data-geo="${item.value.geocode.locationId}">
//                     <div class="movements__type movements__type--${item.type}">${trimTransaction(item.value.outputTitle)}</div>
//                     <div class="movements__date">${formatDate(item.value.date)}</div>
//                     <div class="movements__value">${item.value.quantity}</div>
//                   </div>`;
//                 } else if (item.type === 'kpi') {
//                     html = `<div id="transaction-kpi" class="movements__row" data-id="${item.value._id}" data-title="${item.value.kpiTitle}" data-type="${item.type}" data-quantity="${item.value.kpiQuantity}" data-date="${item.value.date}" data-geo="${item.value.geocode.locationId}">
//                     <div class="movements__type movements__type--${item.type}">${trimTransaction(item.value.kpiTitle)}</div>
//                     <div class="movements__date">${formatDate(item.value.date)}</div>
//                     <div class="movements__value">${item.value.kpiQuantity}</div>
//                   </div>`;
//                 }
//                 ledger.insertAdjacentHTML('beforeend', html);
//             });
//             updateUIWithData(inputActuals, outcomeActuals, kpiActuals, projectInputActualTotal, projectOutcomeActualTotal, projectActualNetPresentValue, projectActualSroiRatio);
//         })
//         .catch(error => {
//             if (error.response && error.response.status === 404) {
//                 showAlert('error', 'Ledger not found');
//                 // } else if (!inputActuals && !outcomeActuals || inputActuals.length === 0 && outcomeActuals.length === 0) {
//             } else if ((!inputActuals || inputActuals.length) === 0 && (!outcomesActuals && outcomeActuals.length === 0)) {
//                 // } else if (!inputActuals && !outcomeActuals) {
//                 ledger.innerHTML = 'Ledger empty';
//             } else {
//                 ledger.innerHTML = '';
//             }
//         });
// }

const updateUIWithData = (inputActuals, outcomeActuals, kpiActuals, projectInputActualTotal, projectOutcomeActualTotal, projectActualNetPresentValue, projectActualSroiRatio) => {
    inputTotalElement.innerHTML = projectInputActualTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    outcomeTotalElement.innerHTML = projectOutcomeActualTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    npvElement.innerHTML = projectActualNetPresentValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};


export const getLocation = (domElement, dropdownContainer, classNames) => {
    const input = domElement;
    const { dropdownClass, listClass, itemClass } = classNames;
    const dropdown = document.createElement("div");
    // dropdown.classList.add("dropdown");
    dropdown.classList.add(dropdownClass);
    dropdownContainer.appendChild(dropdown);

    input.addEventListener("input", async (e) => {
        dropdown.classList.add("show")
        const TIMEOUT = 100;
        let timeoutId;
        const locationText = e.target.value.trim();
        clearTimeout(timeoutId);
        timeoutId = setTimeout(async function () {
            const url = `/location/=${locationText}`;
            const response = await fetch(url);
            const locations = await response.json();
            renderLocations(locations);
        }, TIMEOUT);
    });

    const renderLocations = function (locations) {
        dropdown.innerHTML = "";
        // ul.innerHTML = "";
        if (locations.length === 0 || input.value === "") {
            dropdown.classList.remove("show");
            return;
        }
        const ul = document.createElement("ul");
        // ul.classList.add("dropdown-list");
        ul.classList.add(listClass);
        dropdown.style.width = input.offsetWidth + "px";
        locations.forEach((location) => {
            // console.log(location);
            const li = document.createElement("li");
            // li.classList.add("dropdown-item");
            li.classList.add(itemClass);
            li.innerText = location.description;
            li.addEventListener("click", async function () {
                input.value = location.description;
                input.dataset.locationId = location.placeId;
                const latLongUrl = `/getLatLong/=${location.placeId}`;
                const respond = await fetch(latLongUrl);
                const { lat, lng } = await respond.json();
                if (lat, lng) {
                    input.dataset.lat = lat;
                    input.dataset.lng = lng;
                } else {
                    showAlert('error', 'Error with geometry.')
                }
            });
            ul.appendChild(li);
        });
        dropdown.appendChild(ul);
    };

    document.addEventListener("click", (e) => {
        if (e.target !== input) {
            dropdown.innerHTML = "";
            dropdown.classList.remove("show");
        }
    });
};

// export const submitActualsUpdate = async (type, data) => {
export const submitActualsUpdate = async (event, formType, ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement, formContainer) => {
    event.preventDefault();
    if (formType === 'input') {
        document.getElementById('btn-update-input').textContent = 'Updating...'
        const inputId = document.getElementById('inputUpdateId').value;
        const projectId = document.getElementById('projectUpdateId').value;
        const quantity = document.getElementById('updateQuantity').value;
        const cost = document.getElementById('updateCost').value;
        const date = document.getElementById('updateDate').value;
        let geocodeElement = document.getElementById('popup-location');
        let geocode = {};
        // let geocode = {};
        if (geocodeElement) {
            const latAttribute = geocodeElement.getAttribute('data-lat');
            const lngAttribute = geocodeElement.getAttribute('data-lng');
            const locationId = geocodeElement.getAttribute('data-location-id');

            // geocode = {
            //     locationId: geocodeElement.getAttribute('data-location-id'),
            // };

            if (locationId !== 'null' && locationId !== null && locationId !== '') {
                geocode.locationId = locationId;
            }
            // if (latAttribute !== 'null' && latAttribute !== null && latAttribute !== '' && !isNaN(latAttribute)) {
            if (latAttribute !== 'null' && latAttribute !== null && latAttribute !== '') {
                geocode.lat = latAttribute;
            }

            // if (lngAttribute !== 'null' && lngAttribute !== null && latAttribute !== '' && !isNaN(lngAttribute)) {
            if (lngAttribute !== 'null' && lngAttribute !== null && latAttribute !== '') {
                geocode.lng = lngAttribute;
            }
            // geocode = {
            //     locationId: geocodeElement.getAttribute('data-location-id'),
            //     lat: geocodeElement.getAttribute('data-lat'),
            //     lng: geocodeElement.getAttribute('data-lng')
            // };
        }
        await updateInputActual(inputId, { quantity, cost, date, geocode });
        await updateProject(projectId)
        // await updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement, sroiElement)
        // await updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement)
        await fetchDataAndUpdateUI(projectId, ledger)
    } else if (formType === 'outcome') {
        document.getElementById('btn-update-outcome').textContent = 'Updating...'
        const outcomeId = document.getElementById('outcomeUpdateId').value;
        const projectId = document.getElementById('projectUpdateId').value;
        const quantity = document.getElementById('updateQuantity').value;
        const date = document.getElementById('updateDate').value;
        let geocodeElement = document.getElementById('popup-location');
        let geocode;
        if (geocodeElement) {
            geocode = {
                locationId: geocodeElement.getAttribute('data-location-id'),
                lat: geocodeElement.getAttribute('data-lat'),
                lng: geocodeElement.getAttribute('data-lng')
            };
        }
        await updateOutcomeActual(outcomeId, { quantity, date, geocode });
        await updateProject(projectId)
        // await updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement, sroiElement)
        // await updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement)
        await fetchDataAndUpdateUI(projectId, ledger)
    } else if (formType === 'kpi') {
        document.getElementById('btn-update-kpi').textContent = 'Updating...'
        const kpiId = document.getElementById('kpiUpdateId').value;
        const projectId = document.getElementById('projectUpdateId').value;
        const kpiQuantity = document.getElementById('updateQuantity').value;
        const date = document.getElementById('updateDate').value;
        let geocodeElement = document.getElementById('popup-location');
        let geocode;
        if (geocodeElement) {
            geocode = {
                locationId: geocodeElement.getAttribute('data-location-id'),
                lat: geocodeElement.getAttribute('data-lat'),
                lng: geocodeElement.getAttribute('data-lng')
            };
        }
        await updateKpiActual(kpiId, { kpiQuantity, date, geocode });
        await updateProject(projectId)
        // await updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement, sroiElement)
        // await updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement)
        await fetchDataAndUpdateUI(projectId, ledger)
    }
    formContainer.classList.remove('popup--visible');
}

export const transactionFormInput = async (formId, title, formType, updateCost, updateQuantity, updateGeo, updateLat, updateLng, updateDate, formContainer, projectId, ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement) => {
    let geoCode = updateGeo;
    if (geoCode === 'null' || typeof geoCode === 'undefined') {
        geoCode = null;
    }
    let geoDescrip = '';
    // put data attributes of lat and lng and location id
    if (geoCode) {
        try {
            const retrieveLocation = `/getLocation/${updateGeo}`;
            const respond = await fetch(retrieveLocation);
            const locationDescription = await respond.json();
            geoDescrip = locationDescription;
        } catch (err) {
            console.error(err);
        }
    }
    // geoDescrip = geoDescrip || 'Add Location';
    let popupForm;
    popupForm = `
        <div class="popup__content--visible" id="popup-scale">
        <div class="popup--create__left">
        <h2 class="heading-input u-margin-bottom-small">${trimTransactionTitle(title)}</h2>
        <form id="form-update-input-actual" class="form form-update-input-actual">
            <div class="row">
            <div class="col-1-of-2">
            <input type="hidden" id="inputUpdateId" value="${formId}">
            <input type="hidden" id="projectUpdateId" value="${projectId}">
                <label class="form-account__label" for="title">Quantity</label>
                <input id="updateQuantity" class="form-account__input" type="number" required name="title" value="${updateQuantity}">
                </div>
                <div class="col-1-of-2">
                <label class="form-account__label" for="title">Cost (£)</label>
                <input id="updateCost" class="form-account__input" type="number" step="00.01" required name="updateCost" value="${updateCost}">
                </div>
            </div>
            <div class="row">
            <div class="col-1-of-2">
            <label class="form-account__label" for="title">Geography</label>
            <input id="popup-location" class="form-account__input" type="text" name="updateGeo" value="${geoDescrip}">
            <div id="popup-location-container" class="outcome-location-list">
            </div>
            </div>
                <div class="col-1-of-2">
                <label class="form-account__label" for="title">Date</label>
                <input id="updateDate" class="form-account__input" type="date" required name="updateDate" value="${updateDate}">
                </div>
            </div>
            <a class="transaction__popup--close" href="#">&times;</a>
            <div class="form-account__group right">
                <button class="btn-account btn--small btn--red btn--update--inputactual" id="btn-delete-input">Delete</button>
                <button class="btn-account btn--small btn--orange btn--update--inputactual" id="btn-update-input" type="submit">Update Input</button>
            </div>
        </form>
    </div>
    </div>`
    formContainer.innerHTML = popupForm;
    formContainer.classList.add('popup--visible');
    // Assuming that geoCode, latitude, and longitude have been defined previously
    document.getElementById("popup-location").setAttribute("data-location-id", updateGeo);
    document.getElementById("popup-location").setAttribute("data-lat", updateLat);
    document.getElementById("popup-location").setAttribute("data-lng", updateLng);

    const popupClose = formContainer.querySelector('.transaction__popup--close');
    popupClose.addEventListener('click', (e) => {
        closePopup(formContainer, e)
    });
    const popupLocation = document.getElementById('popup-location');
    const popupLocationContainer = document.querySelector("#popup-location-container");
    if (popupLocation) {
        popupLocation.addEventListener('input', () => {
            getLocation(popupLocation, popupLocationContainer, {
                dropdownClass: "input-actual-dropdown",
                listClass: "input-actual-dropdown-ul",
                itemClass: "input-actual-dropdown-item"
            });
        })
    }
    let updateInputActualPopupForm = document.getElementById('form-update-input-actual');

    updateInputActualPopupForm.addEventListener('submit', (event) => {
        submitActualsUpdate(event, 'input', ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement, formContainer);
    });
    let deleteInputButton = document.getElementById('btn-delete-input');
    deleteInputButton.addEventListener('click', async (e) => {
        e.preventDefault();
        await deleteInputActual(formId)
        closePopup(formContainer, e)
        await updateProject(projectId)
        // updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement, sroiElement)
        // updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement)
        await fetchDataAndUpdateUI(projectId, ledger)
    })

}

export const transactionFormOutcome = async (formId, title, formType, updateQuantity, updateGeo, updateLat, updateLng, updateDate, formContainer, projectId, ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement) => {
    let geoCode = updateGeo;
    if (geoCode === 'null' || typeof geoCode === 'undefined') {
        geoCode = null;
    }
    let geoDescrip = '';
    if (geoCode) {
        try {
            const retrieveLocation = `/getLocation/${updateGeo}`;
            const respond = await fetch(retrieveLocation);
            const locationDescription = await respond.json();
            geoDescrip = locationDescription;
        } catch (err) {
            console.error(err);
        }
    }
    // geoDescrip = geoDescrip || 'Add Location';
    let popupForm;
    popupForm = `
        <div class="popup__content--visible">
        <div class="popup--create__left">
        <h2 class="heading-account u-margin-bottom-small">${trimTransactionTitle(title)}</h2>
        <form id="form-update-outcome-actual" class="form form-update-outcome-actual">
            <div class="row">
            <div class="col-1-of-2">
            <input type="hidden" id="outcomeUpdateId" value="${formId}">
            <input type="hidden" id="projectUpdateId" value="${projectId}">
                <label class="form-account__label" for="title">Quantity</label>
                <input id="updateQuantity" class="form-account__input" type="number" required name="title" value="${updateQuantity}">
                </div>
                <div class="col-1-of-2">
                <label class="form-account__label" for="outcomeDate">Date</label>
                <input id="updateDate" class="form-account__input" type="date" required name="updateDate" value="${updateDate}">
                </div>
            </div>
            <div class="row">
            <div class="col-1-of-2">
            <label class="form-account__label" for="title">Geography</label>
            <input id="popup-location" class="form-account__input" type="text" name="updateGeo" value="${geoDescrip}">
            <div id="popup-location-container" class="outcome-location-list">
            </div>
            </div>
                <div class="col-1-of-2">
                <label class="form-account__label" for="title"></label>
                </div>
            </div>
            <a class="transaction__popup--close" href="#">&times;</a>
            <div class="form-account__group right">
            <button class="btn-account btn--small btn--red btn--update--outcomeactual" id="btn-delete-outcome">Delete</button>
            <button class="btn-account btn--small btn--green btn--update--outcomeactual" id="btn-update-outcome" type="submit">Update Outcome</button>
            </div>
        </form>
    </div>
    </div>`
    formContainer.innerHTML = popupForm;
    formContainer.classList.add('popup--visible');
    document.getElementById("popup-location").setAttribute("data-location-id", updateGeo);
    document.getElementById("popup-location").setAttribute("data-lat", updateLat);
    document.getElementById("popup-location").setAttribute("data-lng", updateLng);

    const popupClose = formContainer.querySelector('.transaction__popup--close');
    popupClose.addEventListener('click', (e) => {
        closePopup(formContainer, e)
    });
    const popupLocation = document.getElementById('popup-location');
    const popupLocationContainer = document.querySelector("#popup-location-container");
    if (popupLocation) {
        popupLocation.addEventListener('input', () => {
            getLocation(popupLocation, popupLocationContainer, {
                dropdownClass: "input-actual-dropdown",
                listClass: "input-actual-dropdown-ul",
                itemClass: "input-actual-dropdown-item"
            });
        })
    }
    let updateOutcomeActualPopupForm = document.getElementById('form-update-outcome-actual');
    updateOutcomeActualPopupForm.addEventListener('submit', (event) => {
        submitActualsUpdate(event, 'outcome', ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement, formContainer);
    });
    let deleteOutcomeButton = document.getElementById('btn-delete-outcome');
    deleteOutcomeButton.addEventListener('click', async (e) => {
        e.preventDefault();
        await deleteOutcomeActual(formId)
        closePopup(formContainer, e)
        await updateProject(projectId)
        // updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement, sroiElement)
        // updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement)
        await fetchDataAndUpdateUI(projectId, ledger)
    })
}

export const transactionFormKpi = async (formId, title, formType, updateQuantity, updateGeo, updateLat, updateLng, updateDate, formContainer, projectId, ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement) => {
    let geoCode = updateGeo;
    if (geoCode === 'null' || typeof geoCode === 'undefined') {
        geoCode = null;
    }
    let geoDescrip = '';
    if (geoCode) {
        try {
            const retrieveLocation = `/getLocation/${updateGeo}`;
            const respond = await fetch(retrieveLocation);
            const locationDescription = await respond.json();
            geoDescrip = locationDescription;
        } catch (err) {
            console.error(err);
        }
    }
    // geoDescrip = geoDescrip || 'Add Location';
    let popupForm;
    popupForm = `
        <div class="popup__content--visible">
        <div class="popup--create__left">
        <h2 class="heading-kpi u-margin-bottom-small">${trimTransactionTitle(title)}</h2>
        <form id="form-update-kpi-actual" class="form form-update-kpi-actual">
            <div class="row">
            <div class="col-1-of-2">
            <input type="hidden" id="kpiUpdateId" value="${formId}">
            <input type="hidden" id="projectUpdateId" value="${projectId}">
                <label class="form-account__label" for="title">Quantity</label>
                <input id="updateQuantity" class="form-account__input" type="number" required name="title" value="${updateQuantity}">
                </div>
                <div class="col-1-of-2">
                <label class="form-account__label" for="outcomeDate">Date</label>
                <input id="updateDate" class="form-account__input" type="date" required name="updateDate" value="${updateDate}">
                </div>
            </div>
            <div class="row">
            <div class="col-1-of-2">
            <label class="form-account__label" for="title">Geography</label>
            <input id="popup-location" class="form-account__input" type="text" name="updateGeo" value="${geoDescrip}">
            <div id="popup-location-container" class="outcome-location-list">
            </div>
            </div>
                <div class="col-1-of-2">
                <label class="form-account__label" for="title"></label>
                </div>
            </div>
            <a class="transaction__popup--close" href="#">&times;</a>
            <div class="form-account__group right">
            <button class="btn-account btn--small btn--red btn--update--kpiactual" id="btn-delete-kpi">Delete</button>
            <button class="btn-account btn--small btn--purple btn--update--kpiactual" id="btn-update-kpi" type="submit">Update KPI</button>
            </div>
        </form>
    </div>
    </div>`
    formContainer.innerHTML = popupForm;
    formContainer.classList.add('popup--visible');
    document.getElementById("popup-location").setAttribute("data-location-id", updateGeo);
    document.getElementById("popup-location").setAttribute("data-lat", updateLat);
    document.getElementById("popup-location").setAttribute("data-lng", updateLng);

    const popupClose = formContainer.querySelector('.transaction__popup--close');
    popupClose.addEventListener('click', (e) => {
        closePopup(formContainer, e)
    });
    const popupLocation = document.getElementById('popup-location');
    const popupLocationContainer = document.querySelector("#popup-location-container");
    if (popupLocation) {
        popupLocation.addEventListener('input', () => {
            getLocation(popupLocation, popupLocationContainer, {
                dropdownClass: "input-actual-dropdown",
                listClass: "input-actual-dropdown-ul",
                itemClass: "input-actual-dropdown-item"
            });
        })
    }
    let updateKpiActualPopupForm = document.getElementById('form-update-kpi-actual');
    updateKpiActualPopupForm.addEventListener('submit', (event) => {
        submitActualsUpdate(event, 'kpi', ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement, formContainer);
    });
    let deleteKpiButton = document.getElementById('btn-delete-kpi');
    deleteKpiButton.addEventListener('click', async (e) => {
        e.preventDefault();
        await deleteKpiActual(formId)
        closePopup(formContainer, e)
        await updateProject(projectId)
        await fetchDataAndUpdateUI(projectId, ledger)
    })
}

const closePopup = (formContainer, e) => {
    e.preventDefault();
    formContainer.classList.remove('popup--visible');
}

export const editSupplierPopup = async (formContainer, userId, userName, userEmail, userSupplierId, userSupplierName, userSupplierCRN, userSupplierAddress, organisationId) => {
    let editForm;
    editForm = `<div class="popup__content--visible">
    <div class="popup--create__left">
    <h2 class="heading-account u-margin-bottom-small">Edit / Delete User</h2>
    <form id="form-update-supplier-user" class="form form-update-kpi-actual">
        <div class="row">
        <div class="col-1-of-2">
        <input type="hidden" id="userUpdateId" value="${userId}">
        <input type="hidden" id="projectUpdateId" value="">
            <label class="form-account__label" for="name">Name</label>
            <input id="update-name" class="form-account__input" type="text" required name="updateName" value="${userName}">
            </div>
            <div class="col-1-of-2">
            <label class="form-account__label" for="updateEmail">Email</label>
            <input id="update-email" class="form-account__input" type="text" required name="updateEmail" value="${userEmail}">
            </div>
        </div>
        <div class="row">
        <div class="col-1-of-2">
        <label class="form-account__label" for="supplier">Supplier Name</label>
            <input id="update-supplier-id"  type="hidden" required name="updateSupplierId" value="${userSupplierId}">
            <input id="update-supplier-name" class="form-account__input" type="text" required name="updateSupplierName" value="${userSupplierName}">
        </div>
            <div class="col-1-of-2">
            <label class="form-account__label" for="supplier">Current Password</label>
            <input id="password-current" class="form-account__input" type="password" placeholder='••••••••' minlength='8' name="password-current">
            </div>
        </div>
        <div class="row">
        <div class="col-1-of-2">
        <label class="form-account__label" for="supplier">New Password</label>
            <input id="password" class="form-account__input" type="password" name="password" placeholder='••••••••' minlength='8' name="password">
        </div>
            <div class="col-1-of-2">
            <label class="form-account__label" for="supplier">Confirm Password</label>
            <input id="password-confirm" class="form-account__input" type="password" placeholder='••••••••' minlength='8' name="password-confirm">
            </div>
        </div>
        <div class="row">
        <div class="col-1-of-2">
        <label class="form-account__label" for="supplier">CRN</label>
            <input id="update-supplier-crn" class="form-account__input" type="number" name="crn" value="${userSupplierCRN}">
        </div>
            <div class="col-1-of-2">
            <label class="form-account__label" for="supplier">Address</label>
            <input id="update-supplier-address" class="form-account__input" type="text" name="address" value="${userSupplierAddress}">
            </div>
        </div>
        <a class="transaction__popup--close" href="#">&times;</a>
        <div class="form-account__group right">
        <button class="btn-account btn--small btn--red btn--update--kpiactual" id="btn-delete-supplier-user">Delete User</button>
        <button class="btn-account btn--small btn--green" id="btn-update-supplier" type="submit">Update User</button>
        </div>
    </form>
</div>
</div>`;
    formContainer.innerHTML = editForm;
    formContainer.classList.add('popup--visible');
    const popupClose = formContainer.querySelector('.transaction__popup--close');
    popupClose.addEventListener('click', (e) => {
        closePopup(formContainer, e)
    });
    let updateSupplierPopupForm = document.getElementById('form-update-supplier-user');
    updateSupplierPopupForm.addEventListener('submit', async (event) => {
        event.preventDefault();
        document.getElementById('btn-update-supplier').textContent = 'Updating...'
        const userId = document.getElementById('userUpdateId').value;
        const name = document.getElementById('update-name').value;
        const email = document.getElementById('update-email').value;
        const supplierId = document.getElementById('update-supplier-id').value;
        const title = document.getElementById('update-supplier-name').value;
        const crn = document.getElementById('update-supplier-crn').value;
        const address = document.getElementById('update-supplier-address').value;
        const passwordCurrent = document.getElementById('password-current').value;
        const newPassword = document.getElementById('password').value;
        const newPasswordConfirm = document.getElementById('password-confirm').value;
        if (newPassword !== '' || newPasswordConfirm !== '' || passwordCurrent !== '') {
            await updateSupplier({ userId, name, email, passwordCurrent, newPassword, newPasswordConfirm, organisationId, supplierId, title, crn, address }, 'password')
        } else {
            await updateSupplier({ userId, name, email, organisationId, supplierId, title, crn, address }, 'data')
        }
    });
    let deactivateSupplierButton = document.getElementById('btn-delete-supplier-user');
    deactivateSupplierButton.addEventListener('click', async (e) => {
        e.preventDefault();
        await deactivateSupplier({ userId, organisationId })
        closePopup(formContainer, e)
        // await updateProject(projectId)
        // await fetchDataAndUpdateUI(projectId, ledger)
    })
}

export const createNewSupplierUserForm = async (formContainer, supplierId, supplierName) => {
    let createSupplierUserHtmlForm;
    createSupplierUserHtmlForm = `
        <div class="popup__content--visible" id="popup-scale">
        <div class="popup--create__left">
        <h2 class="heading-account u-margin-bottom-small">Create External Evaluation User for ${supplierName}</h2>
             <form class="form form-create-supplier-user">
            <input type="hidden" id="supplierUpdateId" value="${supplierId}">
            <div class="row">
                                <div class="col-1-of-2">
                                    <label class="form-account__label" for="userName">Name</label>
                                    <input id="userName" class="form-account__input" type="text" required name="userName">
                                </div>
                                <div class="col-1-of-2">
                                    <label class="form-account__label" for="userEmail">Email</label>
                                    <input id="userEmail" class="form-account__input" type="email" name="userEmail">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-1-of-2">
                                    <label class="form-account__label" for="userPassword">Password (at least 8 characters please)</label>
                                    <input id="userPassword" class="form-account__input" type="text" required name="userPassword" minlength="8">
                                </div>
                                <div class="col-1-of-2">
                                    <label class="form-account__label" for="userPasswordConfirm">Password Confirm</label>
                                    <input id="userPasswordConfirm" class="form-account__input" type="text" required name="userPasswordConfirm">
                                </div>
                            </div>
                            
            <a class="transaction__popup--close" href="#">&times;</a>
            <div class="form-account__group right">
            <div class="col-1-of-2">
                                    <label class="form-account__label">Please remember to write down the password! On submit it is encrypted and can't be retrieved (if forgotten will need to be reset)</label>
                                </div>
                                <button class="btn-account btn--small btn--green btn--create-supplier" type="submit">Create User</button>
            </div>
        </form>
    </div>
    </div>`
    formContainer.innerHTML = createSupplierUserHtmlForm;
    formContainer.classList.add('popup--visible');
    const popupClose = formContainer.querySelector('.transaction__popup--close');
    popupClose.addEventListener('click', (e) => {
        closePopup(formContainer, e)
    });
    const createSupplierUserForm = document.querySelector('.form-create-supplier-user');
    createSupplierUserForm.addEventListener('submit', function (e) {
        e.preventDefault();
        const name = document.getElementById('userName').value;
        const email = document.getElementById('userEmail').value;
        const password = document.getElementById('userPassword').value;
        const passwordConfirm = document.getElementById('userPasswordConfirm').value;
        const supplier = document.getElementById('supplierUpdateId').value;
        createSupplierUser({ name, email, password, passwordConfirm, supplier })
    })
}

export const removeSupplierUser = async (formContainer, supplierUserId, supplierUserName, userOrganisation) => {
    let removeSupplierUserWarning;
    removeSupplierUserWarning = `
    <div class="popup__content--visible" id="popup-scale">
        <div class="popup--create__left">
        <h2 class="heading-account u-margin-bottom-small">Remove External Evaluation User</h2>
            <h3 class="heading-tertiary u-margin-bottom-small ma-bt-lg">
                Are you sure you want to remove the external evaluation user '${supplierUserName}' from ${userOrganisation}? This action will also remove them from any projects for your organisation.
            </h3>
            <form class="form form-delete-project">
                <input id="removeSupplierUserId" type="hidden" value="${supplierUserId}" />
                <div class="form-account__group right">
                    <button class="btn-account btn--small btn--red btn--delete-project" type="submit">Remove</button>
                    <a href="#">
                        <button class="btn-account btn--small btn--green btn--no-delete" type="button">Cancel</button>
                    </a>
                </div>
            </form>
            <a class="transaction__popup--close" href="#">&times;</a>
        </div>
    </div>
</div>
    `
    formContainer.innerHTML = removeSupplierUserWarning;
    formContainer.classList.add('popup--visible');
    const popupClose = formContainer.querySelector('.transaction__popup--close');
    popupClose.addEventListener('click', (e) => {
        closePopup(formContainer, e)
    });
    const removeButton = document.querySelector('.btn--delete-project');
    const cancelButton = document.querySelector('.btn--no-delete');
    removeButton.addEventListener('click', function (e) {
        e.preventDefault();
        removeSupplierUserFromOrg({ supplierUserId })
        closePopup(formContainer, e);
    });
    // Add a click event listener to the 'Cancel' button
    cancelButton.addEventListener('click', function (e) {
        e.preventDefault();
        closePopup(formContainer, e);
    });
}

export const fetchGeo = async function fetchData(updateGeo) {
    try {
        const retrieveLocation = `/getLocation/${updateGeo}`;
        const response = await fetch(retrieveLocation);
        const locationDescription = await response.json();
        return locationDescription;
    } catch (err) {
        // throw err; // Optionally re-throw the error for handling in the calling code
        return err;
    }
}