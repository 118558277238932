import { transferProject } from './project';
import { fetchColleagueProjects, updateColleagueActive, updateColleagueSettings, updateColleaguePassword, createColleague } from './admin';
import { showAlert } from './alerts';

import { myOrganisationUserDropdown, myOrganisationCreateUser } from './index';

export function initialiseColleagues() {
    const itemsPerPage = 10;
    let currentPage = 1;
    const nextButton = document.getElementById('nextButton');
    const prevButton = document.getElementById('prevButton');
    const projectElements = document.querySelectorAll('.transaction transaction--outcome draggable');
    const colleagueElements = document.querySelectorAll('.colleague__card');
    let selectedColleagueId;

    function openProjectInNewTab(event) {
        const projectId = event.currentTarget.getAttribute('data-project-id');
        const link = `/report/${projectId}`;
        window.open(link, '_blank');
    }

    const updateUIWithColleagueProjects = async (page, selectedColleagueId) => {
        const loadProjectsContainer = document.getElementById('load-projects');
        try {
            const projects = await fetchColleagueProjects({ selectedColleagueId });
            // Clear existing projects
            loadProjectsContainer.innerHTML = '';
            // Append new projects to the container
            // If the projects array is empty, display a message
            if (projects.length === 0) {
                const messageElement = document.createElement('div');
                messageElement.className = 'no-projects-message';
                messageElement.textContent = 'Colleague currently has no projects.';
                loadProjectsContainer.appendChild(messageElement);
            } else {
                // Calculate start and end indices based on the current page
                const startIndex = (page - 1) * itemsPerPage;
                const endIndex = startIndex + itemsPerPage;
                const projectsForPage = projects.slice(startIndex, endIndex);

                projectsForPage.forEach((project) => {
                    showAlert('success', `Loading...`)
                    const projectElement = document.createElement('div');
                    projectElement.className = 'transaction transaction--outcome draggable';
                    projectElement.setAttribute('data-project-id', project._id);
                    projectElement.innerHTML = `<div class="transaction__details colleague-project"><span class="colleague-project-name">${project.name}</span></div>`;

                    // Attach double click event to open project in new tab
                    projectElement.addEventListener('dblclick', openProjectInNewTab);

                    // Add draggable attribute
                    projectElement.draggable = true;

                    // Add drag event listener
                    projectElement.addEventListener('dragstart', handleDragStart);

                    // Append the project element to the container
                    loadProjectsContainer.appendChild(projectElement);
                });
                addDragListenersToProjects();
            }
        } catch (err) {
            console.error(err);
        }
    };
    const initProjects = async () => {
        // Load projects for the selected user on initial page load
        document.addEventListener('DOMContentLoaded', async () => {
            selectedColleagueId = myOrganisationUserDropdown.value;
            showAlert('success', `Loading...`)
            // Fetch and display initial projects
            await updateUIWithColleagueProjects(currentPage, selectedColleagueId);
        });

        // Example: Handle a "Next" button click
        nextButton.addEventListener('click', async () => {
            currentPage++;
            await updateUIWithColleagueProjects(currentPage, selectedColleagueId);
        });
        // Example: Handle a "Previous" button click
        prevButton.addEventListener('click', async () => {
            if (currentPage > 1) {
                currentPage--;
                await updateUIWithColleagueProjects(currentPage, selectedColleagueId);
            }
        });
        myOrganisationUserDropdown.addEventListener('change', async () => {
            selectedColleagueId = myOrganisationUserDropdown.value;
            // fetch projects for the selected user
            currentPage = 1;
            await updateUIWithColleagueProjects(currentPage, selectedColleagueId);
        });
    };

    // Call the init function
    initProjects();

    const availableColleaguesContainer = document.getElementById('available-colleagues');
    // Add event listeners for drag events
    function addDragListenersToProjects() {
        const draggableProjects = document.querySelectorAll('#load-projects .draggable');
        draggableProjects.forEach((project) => {
            project.draggable = true;
            project.addEventListener('dragstart', handleDragStart);
        });
    }
    addDragListenersToProjects();
    availableColleaguesContainer.addEventListener('dragover', handleDragOver);
    availableColleaguesContainer.addEventListener('drop', handleDrop);

    // Drag start event handler
    function handleDragStart(event) {
        const projectId = event.target.dataset.projectId;
        const projectName = event.target.textContent;

        // Combine project name and project ID as a JSON string
        const draggedData = JSON.stringify({ projectId, projectName });

        event.dataTransfer.setData('text/plain', draggedData);
    }
    // Drag over event handler (allow drop)
    function handleDragOver(event) {
        const droppableElementClass = 'colleague__card';

        // Check if the dragged element is over an element with the specified class
        if (event.target.classList.contains(droppableElementClass)) {
            event.preventDefault();
            // event.target.style.backgroundColor = '#ffb900';
            event.target.style.backgroundColor = '#007569';
            event.target.style.color = 'white';
        }
    }
    document.addEventListener('dragleave', handleDragLeave);

    function handleDragLeave(event) {
        const droppableElementClass = 'colleague__card';

        // Check if the dragged element leaves an element with the specified class
        if (event.target.classList.contains(droppableElementClass)) {
            event.target.style.backgroundColor = ''; // Reset to the default color or remove the style
            event.target.style.color = ''; // Reset to the default color or remove the style
        }
    }
    // Drop event handler
    async function handleDrop(event) {
        event.preventDefault();

        // Extract data from the dropped element
        const draggedData = event.dataTransfer.getData('text/plain');
        const { projectId, projectName } = JSON.parse(draggedData);

        // Find the closest parent element with the data-user-id attribute
        const dropZone = event.target.closest('[data-user-id]');

        // Extract user ID from the drop zone's data attributes
        const user = dropZone.dataset.userId;

        // Display a confirmation dialog
        const targetUserName = dropZone.textContent.trim();
        const isConfirmed = confirm(`Are you sure you want to move '${projectName}' to ${targetUserName}?`);

        if (isConfirmed) {
            try {
                // Call the asynchronous function to update the project
                await transferProject(projectId, { user });

                // Remove dropped project from the UI
                const draggedElement = document.querySelector(`[data-project-id="${projectId}"]`);
                if (draggedElement) {
                    draggedElement.remove();
                }
            } catch (error) {
                showAlert('error', `Error updating project`);
            }
        }
        // Reset background color and text color
        dropZone.style.backgroundColor = '';
        dropZone.style.color = '';
    }

    let colleagueCurrentPage = 0;
    let colleaguePageSize = 8;
    let colleagueNextButton = document.getElementById('colleagues-nextButton')
    let colleaguePrevButton = document.getElementById('colleagues-prevButton')

    colleagueNextButton.addEventListener('click', loadNextColleagues);
    colleaguePrevButton.addEventListener('click', loadPrevColleagues);

    function loadNextColleagues() {
        colleagueCurrentPage++;
        updateColleaguesVisibility();
    }
    function loadPrevColleagues() {
        colleagueCurrentPage = Math.max(0, colleagueCurrentPage - 1);
        updateColleaguesVisibility();
    }

    function updateColleaguesVisibility() {
        const colleagues = document.querySelectorAll('.colleague__dropzone');
        const totalPages = Math.ceil(colleagues.length / colleaguePageSize);
        colleagues.forEach((colleague, index) => {
            colleague.style.display = index >= colleagueCurrentPage * colleaguePageSize && index < (colleagueCurrentPage + 1) * colleaguePageSize ? 'flex' : 'none';
        });
        const hasMorePages = colleagueCurrentPage < totalPages - 1;
        colleagueNextButton.style.display = hasMorePages ? 'block' : 'none';
    }
    updateColleaguesVisibility();

    // click event handlers for colleagues
    function openInNewTab(event) {
        // Prevent the default behavior to avoid interference with drag-and-drop
        event.preventDefault();

        // Access the colleague ID directly from the dataset of the colleague card element
        const colleagueId = event.currentTarget.dataset.userId;
        // const link = `/colleague/${colleagueId}/page/1`;
        const link = `/settings/${colleagueId}`;

        // Open the link in a new tab
        window.open(link, '_blank');
    }
    // Attach the click event to colleague elements
    colleagueElements.forEach((colleagueElement) => {
        colleagueElement.addEventListener('dblclick', openInNewTab);
    });
    // create user if lead
    if (myOrganisationCreateUser) {
        myOrganisationCreateUser.addEventListener('submit', async (e) => {
            e.preventDefault()
            document.querySelector('.btn--create-user').textContent = 'Creating...'

            const name = document.getElementById('userName').value
            const email = document.getElementById('userEmail').value
            const password = document.getElementById('userPassword').value
            const passwordConfirm = document.getElementById('userPasswordConfirm').value
            // const organisationId = document.getElementById('userOrganisation').value
            // alert({ name, email, password, passwordConfirm })

            await createColleague({ name, email, password, passwordConfirm })
        })
    }
}

export function colleagueSettings() {
    let btnActivation = document.getElementById('btnActivation');
    let selectedColleagueId = document.getElementById('colleagueId').value;
    let colleagueSettingsForm = document.querySelector('.form-colleague-data');
    let colleaguePasswordForm = document.querySelector('.form-colleague-password');
    const isActivatedString = document.getElementById('colleagueActive').value;
    const isActivated = isActivatedString === 'true'; // Convert to boolean

    const iconSpan = document.createElement('span');
    iconSpan.classList.add('btn__icon');
    const updateButtonState = (isActive) => {
        if (isActive) {
            btnActivation.textContent = 'Active';
            btnActivation.classList.remove('btn--waiting');
            btnActivation.classList.remove('btn--deactivated');
            btnActivation.classList.add('btn--activated');
            btnActivation.appendChild(iconSpan);
        } else {
            btnActivation.textContent = 'Inactive';
            btnActivation.classList.remove('btn--waiting');
            btnActivation.classList.remove('btn--activated');
            btnActivation.classList.add('btn--deactivated');
            // btnActivation.classList.add('btn--waiting');
            btnActivation.appendChild(iconSpan);
        }
    };
    // Initial button state
    updateButtonState(isActivated);
    btnActivation.addEventListener('click', async () => {
        try {
            btnActivation.textContent = 'Waiting...';
            btnActivation.appendChild(iconSpan);
            btnActivation.classList.remove('btn--activated');
            btnActivation.classList.remove('btn--deactivated');
            btnActivation.classList.add('btn--waiting');
            btnActivation.disabled = true;
            const returnedColleague = await updateColleagueActive({ selectedColleagueId });
            updateButtonState(returnedColleague.data.updatedUser.active);
            btnActivation.disabled = false;
        } catch (error) {
            showAlert('Error updating colleague status. Please try again later.');
            btnActivation.disabled = false;
        }
    });
    colleagueSettingsForm.addEventListener('submit', (e) => {
        e.preventDefault()
        const name = document.getElementById('colleague-name').value
        const email = document.getElementById('colleague-email').value
        const colleagueId = document.getElementById('colleagueId').value
        // updateColleagueSettings({ name, email }, 'data')
        updateColleagueSettings({ colleagueId, name, email })
    });
    // change password
    colleaguePasswordForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--save-password').textContent = 'Updating...'

        const password = document.getElementById('password').value
        const passwordConfirm = document.getElementById('password-confirm').value
        const colleagueId = document.getElementById('colleagueId').value
        await updateColleaguePassword({ password, passwordConfirm, colleagueId }, 'password')

        document.querySelector('.btn--save-password').textContent = 'Save Password'
        document.getElementById('password').value = ''
        document.getElementById('password-confirm').value = ''
    })

}