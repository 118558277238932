import axios from 'axios'
import { showAlert } from './alerts'

export const updateSettings = async (data, type) => {
    try {
        const url = type === 'password' ? '/api/v1/users/updateMyPassword' : '/api/v1/users/updateMe'

        const res = await axios({
            method: 'PATCH',
            url: url,
            data: data
        })
        if (res.data.status === 'success') {
            showAlert('success', `${type.toUpperCase()} updated successfully`)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}

export const submitContact = async (customerEmail, message) => {
    // console.log(data)
    try {
        const res = await axios({
            method: 'POST',
            url: '/api/v1/users/submitContact',
            // data
            data: {
                customerEmail: customerEmail,
                message: message
            }
        })
        if (res.data.status === 'success') {
            showAlert('success', 'Thank you, someone will be in touch shortly!')
            window.setTimeout(() => {
                location.reload()
            }, 5500)
        }
    } catch (err) {
        showAlert('error', err.response.data.message)
    }
}