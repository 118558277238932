import moment from 'moment'
import { addMapMarker } from './mapMarkers.js';
// import 'leaflet.heat';

class Transaction {
    constructor(id, title, date, value, coords, projectid, projectName) {
        this.id = id;
        this.title = title;
        this.date = date;
        this.value = value;
        this.coords = coords;
        this.projectid = projectid;
        this.projectName = projectName;
    }
    // _setDescription() {
    //     const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    //     this.description = `${this.type[0].toUpperCase()}${this.type.slice(1)} on ${months[this.date.getMonth()]
    //         } ${this.date.getDate()}`;
    // }
}

export class Outcome extends Transaction {
    constructor(id, title, date, value, coords, projectid, projectName) {
        super(id, title, date, value, coords, projectid, projectName);
        this.type = 'outcome';
        this.forecast = 'forecast';
        this.designation = 'outcome'
        // this._setDescription();
    }
}

export class OutcomeActual extends Transaction {
    constructor(id, title, date, value, coords, projectid, projectName) {
        super(id, title, date, value, coords, projectid, projectName);
        this.type = 'outcomeActual';
        this.forecast = 'actual'
        this.designation = 'outcome'
        // this._setDescription();
    }
}

export class Input extends Transaction {
    constructor(id, title, date, value, coords, projectid, projectName) {
        super(id, title, date, value, coords, projectid, projectName);
        this.type = 'input';
        this.forecast = 'forecast';
        this.designation = 'input';
        // this._setDescription();
    }
}

export class InputActual extends Transaction {
    constructor(id, title, date, value, coords, projectid, projectName) {
        super(id, title, date, value, coords, projectid, projectName);
        this.type = 'inputActual';
        this.forecast = 'actual';
        this.designation = 'input'
        // this._setDescription();
    }
}

export class Kpi extends Transaction {
    constructor(id, title, date, value, coords, projectid, projectName) {
        super(id, title, date, value, coords, projectid, projectName);
        this.type = 'kpi';
        this.forecast = 'forecast';
        this.designation = 'kpi'
        // this._setDescription();
    }
}

export class KpiActual extends Transaction {
    constructor(id, title, date, value, coords, projectid, projectName) {
        super(id, title, date, value, coords, projectid, projectName);
        this.type = 'kpiActual';
        this.forecast = 'actual';
        this.designation = 'kpi'
        // this._setDescription();
    }
}

export class Mapp {
    // static transactions = [];
    constructor(config) {
        this.map = null;
        this.mapZoomLevel = 10;
        this.mapEvent = null;
        this.transactions = [];
        // this.mapForm = config.mapForm
        // this.latitude = config.latitude;
        // this.longitude = config.longitude;
        // Get user's position
        // this._getPosition();

        // Attach event handlers
        // this.mapForm.addEventListener('submit', this._newTransaction.bind(this));
        // this.inputType.addEventListener('change', this._toggleElevationField);
        // this.containerWorkouts.addEventListener('click', this._moveToPopup.bind(this));
        // this.containerTransactions.addEventListener('click', this._moveToPopup.bind(this));
    }

    _getPosition(latitude, longitude) {
        this._loadMap(latitude, longitude)
    }

    _loadMap(latitude, longitude) {
        this.map = L.map('map').setView([latitude, longitude], this.mapZoomLevel);

        L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(this.map);

        // Handling clicks on map
        // this.map.on('click', this._showForm.bind(this));

        // this.workouts.forEach(work => {
        //     this._renderWorkoutMarker(work);
        // });
    }

    _showForm(mapE) {
        this.mapEvent = mapE;
        mapForm.classList.remove('hidden');
    }

    _hideForm() {
        // Empty inputs

        mapForm.style.display = 'none';
        mapForm.classList.add('hidden');
        setTimeout(() => (mapForm.style.display = 'grid'), 1000);
    }

    _newTransaction(e) {
        // const validInputs = (...inputs) =>
        //     inputs.every(inp => Number.isFinite(inp));
        // const allPositive = (...inputs) => inputs.every(inp => inp > 0);

        e.preventDefault();

        // Get data from form
        const type = inputType.value;
        const { lat, lng } = this.mapEvent.latlng;
        let transaction;

        if (type === 'outcome') {
            transaction = new Outcome(id, title, date, value, [lat, lng], projectid, projectName);
        }

        if (type === 'input') {
            transaction = new Input(id, title, date, value, [lat, lng], projectid, projectName);
        }

        if (type === 'outcomeActual') {
            transaction = new OutcomeActual(id, title, date, value, [lat, lng], projectid, projectName);
        }

        if (type === 'inputActual') {
            transaction = new InputActual(id, title, date, value, [lat, lng], projectid, projectName);
        }

        if (type === 'kpi') {
            transaction = new Kpi(id, title, date, value, [lat, lng], projectid, projectName);
        }

        if (type === 'kpiActual') {
            transaction = new KpiActual(id, title, date, value, [lat, lng], projectid, projectName);
        }

        // Add new object to transaction array
        this.transactions.push(transaction);

        // Render transaction on map as marker
        this._renderTransactionMarker(transaction);

        // Render transaction on list
        this._renderTransaction(transaction);

        // Hide form + clear input fields
        //   this._hideForm();
    }

    // _renderTransactionMarker(transaction) {
    //     if (transaction.coords && transaction.coords.lat && transaction.coords.lng) {
    //         const marker = L.marker(transaction.coords)
    //             .addTo(this.map)
    //             .bindPopup(
    //                 L.popup({
    //                     maxWidth: 250,
    //                     minWidth: 100,
    //                     autoClose: false,
    //                     closeOnClick: false,
    //                     className: `${transaction.type}-popup`,
    //                 })
    //             )
    //             // .setPopupContent(`${transaction.project} ${transaction.title}`);
    //             .setPopupContent(`
    //                         <div>
    //                             <h3>${transaction.projectName}</h3>
    //                             <p>Type: ${transaction.type}</p>
    //                             <p>Title: ${transaction.title}</p>
    //                             <p>Other details...</p>
    //                         </div>
    //                         `);
    //         const openPopupOnClick = function () {
    //             marker.openPopup();
    //         };

    //         marker.addEventListener('click', openPopupOnClick);
    //     }
    // }

    _renderTransactionMarker(transaction, lat, lng) {
        if (
            transaction &&
            transaction.coords &&
            transaction.coords.lat !== null &&
            transaction.coords.lng !== null &&
            typeof transaction.coords.lat === 'number' &&
            typeof transaction.coords.lng === 'number' &&
            !isNaN(transaction.coords.lat) &&
            !isNaN(transaction.coords.lng)
        ) {
            // Determine the circle color based on the transaction type
            let color = '#007569'; // Default color
            if (transaction.type === 'input' || transaction.type === 'inputActual') {
                color = '#ff7730';
            } else if (transaction.type === 'kpi' || transaction.type === 'kpiActual') {
                color = '#64265d';
            }
            // Determine the circle radius based on the transaction value
            // const radius = Math.sqrt(transaction.value) / 20; // Adjust the multiplier as needed
            let radius
            // if (typeof transaction.value === 'number' && transaction.value >= 0) {
            if (transaction.type === 'outcome' || transaction.type === 'outcomeActual' || transaction.type === 'input' || transaction.type === 'inputActual') {
                if (typeof transaction.value === 'number' && transaction.value >= 0) {
                    radius = Math.sqrt(transaction.value) / 20;
                    // Use the radius value for further processing or rendering
                } else {
                    radius = 2;
                    // Handle invalid or negative values
                    // Assign a default radius value or skip the calculation
                }
            } else if (transaction.type === 'kpi' || transaction.type === 'kpiActual') {
                radius = transaction.value / 2;
            }
            function formatValue(transaction) {
                if (transaction.type === 'outcome' || transaction.type === 'outcomeActual' || transaction.type === 'input' || transaction.type === 'inputActual') {
                    return `£${transaction.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
                } else {
                    return `${transaction.value}`;
                }
            }
            const projectId = transaction.projectId;
            const forecast = transaction.forecast;
            const type = transaction.type;
            const circleMarker = L.circleMarker({ lat, lng }, {
                radius,
                color,
                fillColor: color,
                fillOpacity: 0.5,
            }).addTo(this.map)
                .bindPopup(
                    L.popup({
                        maxWidth: 250,
                        minWidth: 100,
                        autoClose: false,
                        closeOnClick: false,
                        className: `${transaction.type}-popup`,
                    })
                )
                .setPopupContent(`
              <div>
                <h2 class="transaction__title">${transaction.projectName}</h2>
                <p>${transaction.title}</p>
                <p>${formatValue(transaction)}</p>
              </div>
            `);
            circleMarker.transactionId = transaction.id;
            circleMarker.projectId = projectId; // Assign the projectId to the marker
            circleMarker.forecast = forecast; // Assign the forecast to the marker
            circleMarker.type = type;
            const openPopupOnClick = function () {
                circleMarker.openPopup();
            };
            circleMarker.addEventListener('click', openPopupOnClick);
            addMapMarker(circleMarker);
        } else {
            console.log('Invalid coordinates:', transaction.coords);
        }
    }


    _renderTransactionHeatmap(transaction) {
        const gradient = this._getGradientByTransactionType(transaction.type);
        if (transaction.coords && transaction.coords.lat && transaction.coords.lng) {
            const heat = L.heatLayer([[transaction.coords.lat, transaction.coords.lng, transaction.value]], {
                radius: transaction.radius,
                gradient: gradient, // Customize the gradient colors
                maxZoom: 15,
                willReadFrequently: true
            }).addTo(this.map).bindPopup(
                L.popup({
                    maxWidth: 250,
                    minWidth: 100,
                    autoClose: false,
                    closeOnClick: false,
                    className: `${transaction.type}-popup`,
                })
            )
                .setPopupContent(`${transaction.project} ${transaction.title}`);

            const openPopupOnClick = function () {
                marker.openPopup();
            };

            heat.addEventListener('click', openPopupOnClick);
        }
    }

    _getGradientByTransactionType(type) {
        let gradient;
        if (type === 'outcome' || type === 'outcomeActual') {
            gradient = { 0.2: '#007569', 0.4: '#00685d', 0.6: '#004e46', 1: '#00423B' };
        } else if (type === 'input' || type === 'inputActual') {
            gradient = { 0.2: '#ffb900', 0.4: '#ffa90e', 0.6: '#ff8726', 1: '#ff7730' };
        } else {
            gradient = { 0.2: 'cyan', 0.4: 'lime', 0.6: 'magenta', 1: 'gray' };
        }
        return gradient;
    }
    // transaction = new Input(title, date, value, [lat, lng]);
    _renderTransaction(transaction, mapForm) {
        const transactionType = transaction.type === 'outcome' || transaction.type === 'input' || transaction.type === 'kpi'
            ? 'Forecast'
            : 'Actual';

        let html = `
        <li class="transaction transaction--${transaction.type}" data-id="${transaction.id}" data-projectid=${transaction.projectid} data-forecast=${transaction.forecast} data-type=${transaction.designation}>
          <h2 class="transaction__title">${transaction.projectName}</h2>
          <div class="transaction__details">
            <span class="transaction__value">${transaction.title}</span>
          </div>
          <div class="transaction__details">
            <span class="transaction__icon"></span>
            <span class="transaction__value">${transactionType}</span>
          </div>
      `;
        if (transaction.type === 'outcome' || transaction.type === 'outcomeActual')
            html += `
          <div class="transaction__details">
            <span class="transaction__value">£${((transaction.value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}</span>
          </div>
          <div class="transaction__details">
            <span class="transaction__value">${moment(transaction.date).format('DD/MM/YYYY')}</span>
          </div>
        </li>
        `;
        if (transaction.type === 'input' || transaction.type === 'inputActual')
            html += `
          <div class="transaction__details">
            <span class="transaction__icon"></span>
            <span class="transaction__value">£${((transaction.value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}</span>
          </div>
          <div class="transaction__details">
            <span class="transaction__icon"></span>
            <span class="transaction__value">${moment(transaction.date).format('DD/MM/YYYY')}</span>
          </div>
        </li>
        `;
        if (transaction.type === 'kpi' || transaction.type === 'kpiActual')
            html += `
          <div class="transaction__details">
            <span class="transaction__icon"></span>
            <span class="transaction__value">${transaction.value}</span>
          </div>
          <div class="transaction__details">
            <span class="transaction__icon"></span>
            <span class="transaction__value">${moment(transaction.date).format('DD/MM/YYYY')}</span>
          </div>
        </li>
        `;
        mapForm.insertAdjacentHTML('afterend', html);
    }

    _moveToPopup(e) {
        // BUGFIX: When we click on a workout before the map has loaded, we get an error. But there is an easy fix:
        if (!this.map) return;

        const transactionEl = e.target.closest('.transaction');

        if (!transactionEl) return;

        const transaction = this.transactions.find(
            trans => trans.id === transactionEl.dataset.id
        );

        this.map.setView(transaction.coords, this.mapZoomLevel, {
            animate: true,
            pan: {
                duration: 1,
            },
        });
    }

    _clearMapMarkers() {
        // Iterate over the existing markers and remove them from the map
        this.map.eachLayer(layer => {
            if (layer instanceof L.CircleMarker) {
                this.map.removeLayer(layer);
            }
        });
    }

    reset() {
        location.reload();
    }
}
